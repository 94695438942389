import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import axiosInstance from "../../axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";

const baseUrl = process.env.REACT_APP_BASE_URL;

const InvitadosTable = () => {
  const [invitados, setInvitados] = useState([]);
  const [filters, setFilters] = useState({
    loteId: "",
    nombre: "",
    ci: "",
    fechaInicio: "",
    fechaTermino: "",
  });
  const [lotes, setLotes] = useState([]);

  useEffect(() => {
    fetchInvitados();
    fetchLotes();
  }, []);

  const fetchLotes = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/api/lotes`);
      setLotes(response.data);
    } catch (error) {
      console.error("Error fetching lotes:", error);
      toast.error("Error al cargar lotes");
    }
  };

  const fetchInvitados = async () => {
    try {
      const response = await axiosInstance.get("/api/invitados/all", { params: filters });
      setInvitados(response.data);
    } catch (error) {
      console.error("Error fetching invitados:", error);
      toast.error("Error al cargar invitados");
    }
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSearch = () => {
    fetchInvitados();
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    const tableColumn = ["Nombre", "CI", "Género", "Email", "FML", "Fecha de Creación"];
    const tableRows = [];

    invitados.forEach((invitado) => {
      const invitadoData = [
        invitado.nombre,
        invitado.cedula_identidad,
        invitado.genero,
        invitado.email,
        invitado.Invitacions[0]
          ? `143 - ${invitado.Invitacions[0].Lote.manzana} - ${invitado.Invitacions[0].Lote.lote}`
          : "N/A",
        new Date(invitado.createdAt).toLocaleString(),
      ];
      tableRows.push(invitadoData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save("listado_invitados.pdf");
  };

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Typography variant="h4" gutterBottom>
        Invitados
      </Typography>
      <Box display="flex" gap={2} marginBottom={2}>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>FML</InputLabel>
          <Select name="loteId" value={filters.loteId || ""} onChange={handleFilterChange}>
            {lotes.map((lote) => (
              <MenuItem key={lote.id} value={lote.id}>
                Manzana: {lote.manzana} - Lote: {lote.lote}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField name="nombre" label="Nombre" onChange={handleFilterChange} />
        <TextField name="ci" label="CI" onChange={handleFilterChange} />
        <TextField
          name="fechaInicio"
          label="Fecha Inicio"
          type="date"
          InputLabelProps={{ shrink: true }}
          onChange={handleFilterChange}
        />
        <TextField
          name="fechaTermino"
          label="Fecha Término"
          type="date"
          InputLabelProps={{ shrink: true }}
          onChange={handleFilterChange}
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Buscar
        </Button>
        <Button variant="contained" color="secondary" onClick={downloadPdf}>
          Descargar PDF
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>CI</TableCell>
              <TableCell>Género</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>FML</TableCell>
              <TableCell>Fecha de Creación</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitados.map((invitado) => (
              <TableRow key={invitado.id}>
                <TableCell>{invitado.nombre}</TableCell>
                <TableCell>{invitado.cedula_identidad}</TableCell>
                <TableCell>{invitado.genero}</TableCell>
                <TableCell>{invitado.email}</TableCell>
                <TableCell>
                  {invitado.Invitacions[0]
                    ? `143 - ${invitado.Invitacions[0].Lote.manzana} - ${invitado.Invitacions[0].Lote.lote}`
                    : "N/A"}
                </TableCell>
                <TableCell>{new Date(invitado.createdAt).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InvitadosTable;
