import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import axiosInstance from "../../axiosInstance";
const baseUrl = process.env.REACT_APP_BASE_URL;
const RegistroObrero = () => {
  const [ci, setCi] = useState("");
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Función para registrar entrada o salida
  const handleRegistro = async (actionType) => {
    if (!ci) {
      setErrorMessage("Por favor, ingrese la cédula.");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setAction(actionType);

    try {
      const response = await axiosInstance.post(`${baseUrl}/api/obreros/registro`, {
        cedula_identidad: ci,
        action: actionType,
      });

      setSuccessMessage(`Registro de ${actionType} exitoso: ${response.data.message}`);
      setCi("");
    } catch (error) {
      setErrorMessage("Error al registrar la acción. Por favor, intente nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, p: 3, boxShadow: 3, borderRadius: 2, backgroundColor: "white" }}>
        <Typography variant="h5" align="center" gutterBottom>
          Registro de Entradas y Salidas
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <TextField
              label="Cédula de Identidad"
              variant="outlined"
              fullWidth
              value={ci}
              onChange={(e) => setCi(e.target.value)}
              placeholder="Ingrese el número de CI"
              disabled={loading}
              error={!ci && errorMessage}
              helperText={!ci && errorMessage}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleRegistro("Entrada")}
              disabled={loading || !ci}
            >
              Registrar Entrada
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => handleRegistro("Salida")}
              disabled={loading || !ci}
            >
              Registrar Salida
            </Button>
          </Grid>
        </Grid>

        {successMessage && (
          <Snackbar
            open={!!successMessage}
            autoHideDuration={4000}
            onClose={() => setSuccessMessage("")}
          >
            <Alert onClose={() => setSuccessMessage("")} severity="success">
              {successMessage}
            </Alert>
          </Snackbar>
        )}

        {errorMessage && (
          <Snackbar
            open={!!errorMessage}
            autoHideDuration={4000}
            onClose={() => setErrorMessage("")}
          >
            <Alert onClose={() => setErrorMessage("")} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </Container>
  );
};

export default RegistroObrero;
