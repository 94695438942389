import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  MenuItem,
  Select,
  Input,
  Chip,
  InputLabel,
  FormControl,
  Box,
  Typography,
  TablePagination,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomSwitch from "../../components/CustomSwitch";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { toast, ToastContainer } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Lotes = () => {
  const { setUser, user } = useContext(UserContext);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [lotes, setLotes] = useState([]);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchOwner, setSearchOwner] = useState("");
  const [currentLote, setCurrentLote] = useState({
    lote: "",
    manzana: "",
    estado: "habilitado",
    userId: "",
    sharedUserIds: [],
  });
  const [shareLote, setShareLote] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userToDelete, setUserToDelete] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [newUserData, setNewUserData] = useState({
    username: "",
    password: "",
    email: "",
    role: "",
    cedula: "", // Añadir este estado para manejar la cédula
    telefono: "", // Añadir este estado para manejar la cédula
    foto: null, // Añadir este estado para manejar la foto
  });
  const [detailOpen, setDetailOpen] = useState(false);
  const [loteDetails, setLoteDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Estado para controlar si se está editando el propietario
  useEffect(() => {
    // Intenta obtener el usuario desde localStorage al cargar la aplicación
    const storedUser = localStorage.getItem("user");
    if (storedUser !== null && typeof storedUser !== "object") {
      setUser(JSON.parse(storedUser)); // Parsea el usuario almacenado en localStorage si no es un objeto
    }
  }, []);

  useEffect(() => {
    fetchLotes();
    fetchUsers();
  }, []);

  const fetchLotes = async () => {
    const storedUser = localStorage.getItem("user");
    const parsedUser = JSON.parse(storedUser);
    const response = await axiosInstance.get(
      parsedUser.role === "admin" ? `${baseUrl}/api/lotes` : `${baseUrl}/api/lotes/getMyLotes`
    );
    setLotes(response.data);
  };

  const fetchUsers = async () => {
    const response = await axiosInstance.get(`${baseUrl}/api/auth/getAllPropietarios`);
    setUsers(response.data);
  };

  const handleOpen = () => {
    setCurrentLote({
      lote: "",
      manzana: "",
      estado: "habilitado",
      userId: "",
      sharedUserIds: [],
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShareOpen = (lote) => {
    setShareLote(lote);
    setShareOpen(true);
    setSelectedUsers([]);
  };

  const handleShareClose = () => {
    setShareOpen(false);
  };

  const handleSave = async () => {
    if (currentLote.id) {
      try {
        await axiosInstance.put(`${baseUrl}/api/lotes/update/${currentLote.id}`, currentLote);
        toast.success("Lote actualizado exitosamente");
      } catch (error) {
        toast.error(error.response.data.error);
      }
    } else {
      try {
        await axiosInstance.post(`${baseUrl}/api/lotes/create`, currentLote);
        toast.success("Lote creado exitosamente");
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
    fetchLotes();
    handleClose();
  };

  const handleEdit = (lote) => {
    if (lote.Users.length !== 0) {
      const selectedUser = users.find((user) => user.id === lote.Users[0].id); // Obtener el usuario seleccionado
      setSelectedUser(selectedUser);
    } else {
      setSelectedUser(null);
    }
    setCurrentLote(lote);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`${baseUrl}/api/lotes/delete/${userToDelete.id}`);
      fetchLotes();
      setConfirmOpen(false);
      toast.success("Usuario eliminado exitosamente");
    } catch (error) {
      toast.error("Error al eliminar el usuario");
    }
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "sharedUserIds") {
      setCurrentLote({
        ...currentLote,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    } else {
      setCurrentLote({ ...currentLote, [name]: value });
    }
  };

  const handleToggleEstado = async (lote) => {
    const updatedLote = {
      ...lote,
      estado: lote.estado === "habilitado" ? "deshabilitado" : "habilitado",
    };
    await axiosInstance.put(`${baseUrl}/api/lotes/updateEstado/${lote.id}`, updatedLote);
    fetchLotes();
  };

  const handleShare = async () => {
    await axiosInstance.post(`${baseUrl}/api/lotes/share`, {
      loteId: shareLote.id,
      userId: selectedUsers,
    });
    setShareOpen(false);
  };

  const handleAddUserDialogOpen = () => {
    setAddUserDialogOpen(true);
  };

  const handleAddUserDialogClose = () => {
    setAddUserDialogOpen(false);
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;
    setNewUserData({ ...newUserData, [name]: value });
  };
  const handleRoleChange = (e) => {
    setNewUserData({ ...newUserData, role: e.target.value });
  };

  const handleFileChange = (e) => {
    setNewUserData({ ...newUserData, foto: e.target.files[0] });
  };

  const navigate = useNavigate();

  const handleRedirectToMediciones = (loteId) => {
    navigate(`/mediciones/${loteId}`);
  };
  const validateForm = () => {
    const newErrors = {};

    if (!newUserData.username) {
      newErrors.username = "El nombre de usuario es obligatorio";
    }

    if (!newUserData.password) {
      newErrors.password = "La contraseña es obligatoria";
    } else if (newUserData.password.length < 6) {
      newErrors.password = "La contraseña debe tener al menos 6 caracteres";
    }

    if (!newUserData.email) {
      newErrors.email = "El correo electrónico es obligatorio";
    } else if (!/\S+@\S+\.\S+/.test(newUserData.email)) {
      newErrors.email = "El correo electrónico no es válido";
    }

    if (!newUserData.cedula) {
      newErrors.cedula = "La cédula es obligatoria";
    } else if (!/^\d+$/.test(newUserData.cedula)) {
      newErrors.cedula = "La cédula debe contener solo números";
    }

    if (!newUserData.telefono) {
      newErrors.telefono = "El teléfono es obligatorio";
    } else if (!/^\d+$/.test(newUserData.telefono)) {
      newErrors.telefono = "El teléfono debe contener solo números";
    }

    if (!newUserData.role) {
      newErrors.role = "El rol es obligatorio";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length !== 0;
  };

  const handleAddUser = async () => {
    if (validateForm()) {
      return toast.error("Formulario no válido");
    }
    const formData = new FormData();
    formData.append("username", newUserData.username);
    formData.append("password", newUserData.password);
    formData.append("email", newUserData.email);
    formData.append("role", newUserData.role);
    formData.append("cedula", newUserData.cedula); // Añadir la cédula al FormData
    formData.append("telefono", newUserData.telefono); // Añadir la cédula al FormData
    formData.append("foto", newUserData.foto); // Añadir la foto al FormData

    try {
      await axiosInstance.post(`${baseUrl}/api/auth/register`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Usuario agregado exitosamente");
      toast.success("Usuario agregado exitosamente");
      fetchUsers();
      setAddUserDialogOpen(false);
    } catch (error) {
      setAddUserDialogOpen(false);
      toast.error(error.response.data.error);

      console.error("Error al agregar usuario:", error);
    }
  };

  const handleDetailOpen = async (loteId) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/api/lotes/${loteId}`);
      setLoteDetails(response.data);
      setDetailOpen(true);
      console.log(response.data);
    } catch (error) {
      console.error("Error al obtener detalles del lote:", error);
    }
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
    setLoteDetails(null);
  };
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Logic for displaying the correct rows per page
  const filteredLotes = lotes.filter((lote) => {
    const matchesCedula =
      searchTerm === "" ||
      lote.Users.length === 0 ||
      lote.Users[0].cedula.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesOwner =
      searchOwner === "" ||
      lote.Users.length === 0 ||
      lote.Users[0].username.toLowerCase().includes(searchOwner.toLowerCase());
    return matchesCedula && matchesOwner;
  });

  const paginatedLotes = filteredLotes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleEditClick = () => {
    setIsEditing(true); // Cambiar a modo edición al hacer clic en el botón de edición
  };

  const handleSelectChange = (event) => {
    setCurrentLote({ ...currentLote, userId: event.target.value });
    const selectedUser = users.find((user) => user.id === event.target.value); // Obtener el usuario seleccionado
    setSelectedUser(selectedUser);
    setIsEditing(false); // Salir de modo edición después de seleccionar un nuevo usuario
  };

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Gestión de Lotes</Typography>
        <TextField
          variant="outlined"
          placeholder="Buscar por propietario"
          name="searchOwner"
          value={searchOwner}
          onChange={(e) => setSearchOwner(e.target.value)}
          sx={{ width: "250px" }}
        />
        <TextField
          variant="outlined"
          placeholder="Buscar por CI de propietario"
          name="searchTerm"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: "250px" }}
        />
        {user && user.role === "admin" && (
          <Box>
            <Button variant="contained" color="primary" onClick={handleOpen} sx={{ mr: 2 }}>
              Agregar Lote
            </Button>
            <Button variant="contained" color="secondary" onClick={handleAddUserDialogOpen}>
              Agregar Usuario
            </Button>
          </Box>
        )}
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }}>Lote</TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Manzana
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Estado
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Propietario
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedLotes.map((lote) => (
              <TableRow key={lote.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {lote.lote}
                </TableCell>
                <TableCell align="right">{lote.manzana}</TableCell>
                <TableCell align="right">{lote.estado}</TableCell>
                <TableCell align="right">
                  {lote.Users.length > 0 ? lote.Users[0].username : "-"}
                </TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleDetailOpen(lote.id)}>
                    <VisibilityIcon />
                  </IconButton>
                  {user && user.role === "admin" && (
                    <>
                      <IconButton color="primary" onClick={() => handleEdit(lote)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color={lote.estado === "habilitado" ? "warning" : "success"}
                        // onClick={() => handleToggleEstado(lote)}
                      >
                        <CustomSwitch
                          checked={lote.estado === "habilitado"}
                          onChange={() => handleToggleEstado(lote)}
                          color="primary"
                        />
                        {/* {lote.estado === "habilitado" ? <BlockIcon /> : <CheckCircleOutlineIcon />} */}
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => handleRedirectToMediciones(lote.id)}
                      >
                        <ContentPasteIcon />
                      </IconButton>
                      <IconButton color="warning" onClick={() => handleDeleteClick(lote)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                  <IconButton color="secondary" onClick={() => handleShareOpen(lote)}>
                    <ShareIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={lotes.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[1, 10, 25, 50, 100]}
          labelRowsPerPage="Mostrar"
        />
      </TableContainer>
      <Dialog open={detailOpen} onClose={handleDetailClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Detalles del Lote</DialogTitle>
        <DialogContent>
          {loteDetails && (
            <>
              <Typography sx={{ padding: "10px" }} variant="body1">
                Lote: {loteDetails.lote.lote}
              </Typography>
              <Typography sx={{ padding: "10px" }} variant="body1">
                Manzana: {loteDetails.lote.manzana}
              </Typography>
              <Typography sx={{ padding: "10px" }} variant="body1">
                Estado: {loteDetails.lote.estado}
              </Typography>
              <Typography sx={{ padding: "10px" }} variant="body1">
                Propietario: {loteDetails.owners.map((owner) => owner.username).join(", ")}
              </Typography>
              <Typography sx={{ padding: "10px" }} variant="body1">
                Copropietario: {loteDetails.guests.map((guest) => guest.username).join(", ")}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDetailClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentLote.id ? "Editar Lote" : "Agregar Lote"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="lote"
            label="Lote"
            type="text"
            fullWidth
            value={currentLote.lote}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="manzana"
            label="Manzana"
            type="text"
            fullWidth
            value={currentLote.manzana}
            onChange={handleChange}
          />
          {!currentLote.id || isEditing ? (
            <FormControl fullWidth margin="dense">
              <InputLabel id="share-user-select-label">Seleccionar Usuario</InputLabel>
              <Select
                labelId="share-user-select-label"
                value={currentLote.userId || ""}
                onChange={handleSelectChange}
                input={<Input />}
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Box display="flex" alignItems="center">
              <TextField
                margin="dense"
                label="Propietario"
                type="text"
                fullWidth
                value={selectedUser ? selectedUser.username : "No asignado"}
                InputProps={{
                  readOnly: true,
                }}
              />
              <IconButton onClick={handleEditClick} color="primary">
                <EditIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={shareOpen} onClose={handleShareClose} fullWidth={true}>
        <DialogTitle>Compartir Lote</DialogTitle>
        <DialogContent>
          {/* Campo de búsqueda */}
          <TextField
            label="Buscar Usuario"
            variant="outlined"
            fullWidth
            margin="dense"
            onChange={(e) => setSearchUser(e.target.value.toLowerCase())}
          />

          <FormControl fullWidth margin="dense">
            <InputLabel id="share-user-select-label">Seleccionar Usuario</InputLabel>
            <Select
              labelId="share-user-select-label"
              multiple
              value={selectedUsers}
              onChange={(e) => setSelectedUsers(e.target.value)}
              input={<Input />}
            >
              {/* Filtrar usuarios según el término de búsqueda */}
              {users
                .filter((user) => user.username.toLowerCase().includes(searchUser))
                .map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.username}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShareClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleShare} color="primary">
            Compartir
          </Button>
        </DialogActions>
      </Dialog>
      {/* Diálogo para agregar usuario */}
      <Dialog open={addUserDialogOpen} onClose={handleAddUserDialogClose}>
        <DialogTitle>Agregar Nuevo Usuario</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="Nombre de Usuario"
            type="text"
            fullWidth
            value={newUserData.username}
            onChange={handleNewUserChange}
            error={!!errors.username}
            helperText={errors.username}
          />
          <TextField
            margin="dense"
            name="password"
            label="Contraseña"
            type="password"
            fullWidth
            value={newUserData.password}
            onChange={handleNewUserChange}
            error={!!errors.password}
            helperText={errors.password}
          />
          <TextField
            margin="dense"
            name="email"
            label="Correo Electrónico"
            type="email"
            fullWidth
            value={newUserData.email}
            onChange={handleNewUserChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            margin="dense"
            name="cedula"
            label="Cédula"
            type="text"
            fullWidth
            value={newUserData.cedula}
            onChange={handleNewUserChange}
            error={!!errors.cedula}
            helperText={errors.cedula}
          />
          <TextField
            margin="dense"
            name="telefono"
            label="Telefono"
            type="text"
            fullWidth
            value={newUserData.telefono}
            onChange={handleNewUserChange}
            error={!!errors.telefono}
            helperText={errors.telefono}
          />
          <FormControl fullWidth margin="dense" error={!!errors.role}>
            <InputLabel id="role-select-label">Rol</InputLabel>
            <Select
              labelId="role-select-label"
              name="role"
              value={newUserData.role}
              onChange={handleRoleChange}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="propietario">Propietario</MenuItem>
              <MenuItem value="portero">Portero</MenuItem>
              <MenuItem value="mantenimiento">Personal de mantenimiento</MenuItem>
              <MenuItem value="casual">Persona casual</MenuItem>
            </Select>
            {errors.role && <FormHelperText>{errors.role}</FormHelperText>}
          </FormControl>
          <input
            type="file"
            name="foto"
            accept="image/*"
            onChange={handleFileChange} // Agregar esta línea para manejar el archivo
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddUserDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAddUser} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de que quieres eliminar a lote {userToDelete?.lote}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Lotes;
