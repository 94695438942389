import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import { Box, Typography, Paper, Grid, Divider, Button } from "@mui/material";

const baseUrl = process.env.REACT_APP_FRONT_URL;
const baseApiUrl = process.env.REACT_APP_BASE_URL;
const FormSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Hook para navegar

  const { invitacion, lote, invitacionToken, fechaInicio, fechaTermino } = location.state || {};
  const invitados = invitacion?.invitados || [];
  //   console.log(invitados);

  if (!invitados.length) {
    return (
      <Typography variant="h5" align="center" mt={5}>
        No se encontró información de los invitados.
      </Typography>
    );
  }

  return (
    <Box textAlign="center" mt={5} px={3}>
      {/* Logo */}
      <Box>
        <img src="/assets/logo-color.png" alt="Logo" style={{ height: 200 }} />
      </Box>

      {/* Grid de invitados */}
      <Grid container spacing={4} justifyContent="center">
        {invitados.map((invitado) => {
          const route = `${baseUrl}/validateEntry/${invitado.token}/${lote}/${invitacionToken}`;
          //   console.log(route);

          const fotoUrl = invitado.foto ? `${baseApiUrl}/${invitado.foto}` : null;

          // Generar objeto JSON para el segundo QR
          const qrData = JSON.stringify({
            token: invitado.token,
            lote: lote,
            invitacionToken: invitacionToken,
          });
          //   console.log(qrData);

          return (
            <Grid item xs={12} sm={6} md={4} key={invitado.id}>
              <Paper elevation={3} style={{ padding: 20, borderRadius: 12 }}>
                {/* Mostrar la foto del invitado */}
                {invitado.foto && (
                  <Box mb={2} display="flex" justifyContent="center">
                    <img
                      src={fotoUrl}
                      alt={`Foto de ${invitado.nombre}`}
                      style={{
                        width: "100%",
                        maxHeight: "300px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                )}
                <Typography variant="h6" gutterBottom>
                  Nombre: {invitado.nombre}
                </Typography>
                <Typography variant="body1">
                  Cédula de Identidad: {invitado.cedula_identidad}
                </Typography>
                <Typography variant="body1">Género: {invitado.genero}</Typography>
                <Typography variant="body1">Edad: {invitado.edad}</Typography>
                <Typography variant="body1" gutterBottom>
                  Email: {invitado.email}
                </Typography>
                <Typography variant="body2" color="text.secondary" mt={1}>
                  <strong>Válido desde:</strong>{" "}
                  {fechaInicio ? new Date(fechaInicio).toLocaleString() : "No asignado"}{" "}
                </Typography>
                <Typography variant="body2" color="text.secondary" mt={1}>
                  <strong>Válido hasta:</strong>{" "}
                  {fechaTermino ? new Date(fechaTermino).toLocaleString() : "No asignado"}
                </Typography>
                <Divider light style={{ margin: "10px 0" }} />
                {/* Primer QR */}
                {/* <Box display="flex" justifyContent="center" mt={2}>
                  <QRCode value={route} size={128} />
                </Box>
                <Divider light style={{ margin: "10px 0" }} />
                
                <Typography variant="body2" color="text.secondary" mt={1}>
                  <strong>Para Aplicacion</strong>
                </Typography> */}
                <Box display="flex" justifyContent="center" mt={2}>
                  <QRCode value={qrData} size={128} />
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>

      {/* Botón para volver atrás */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate(-1)} // Volver a la página anterior
        style={{ marginTop: 20 }}
      >
        Agregar otros invitados
      </Button>
    </Box>
  );
};

export default FormSuccess;
