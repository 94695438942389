import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable"; // para generar tablas automáticamente

const baseUrl = process.env.REACT_APP_BASE_URL;

const Obreros = () => {
  const [obreros, setObreros] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedObreros, setSelectedObreros] = useState([]);
  const [currentObrero, setCurrentObrero] = useState({
    nombre: "",
    apellido: "",
    cedula_identidad: "",
    fecha: "",
    funcion: "",
    estado: "",
    loteId: "",
  });
  const [filters, setFilters] = useState({
    nombre: "",
    cedula_identidad: "",
    loteId: "",
    fecha: "",
  });
  const [obreroToDelete, setObreroToDelete] = useState(null);
  const [lotes, setLotes] = useState([]);
  useEffect(() => {
    fetchObreros();
    fetchLotes();
  }, []);

  const fetchLotes = async () => {
    const response = await axiosInstance.get(`${baseUrl}/api/lotes`);
    setLotes(response.data);
  };
  const fetchObreros = async () => {
    try {
      const params = new URLSearchParams(filters);
      const response = await axiosInstance.get(`${baseUrl}/api/obreros?${params}`);
      setObreros(response.data);
    } catch (error) {
      toast.error(error.response.data.error);
      console.error("Error fetching obreros:", error);
    }
  };

  const handleOpen = () => {
    setCurrentObrero({
      nombre: "",
      apellido: "",
      cedula_identidad: "",
      fecha: "",
      funcion: "",
      estado: "",
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSelectObrero = (id) => {
    setSelectedObreros((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((obreroId) => obreroId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDeleteSelected = async () => {
    try {
      await axiosInstance.delete(`${baseUrl}/api/obreros/deleteMultiple`, {
        data: { ids: selectedObreros },
      });
      toast.success("Obreros eliminados con éxito");
      setObreros(obreros.filter((obrero) => !selectedObreros.includes(obrero.id)));
      setSelectedObreros([]);
    } catch (error) {
      toast.error("Error al eliminar obreros");
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!currentObrero.nombre) {
      newErrors.nombre = "El nombre es obligatorio";
    }
    if (!currentObrero.apellido) {
      newErrors.apellido = "El apellido es obligatorio";
    }
    if (!currentObrero.cedula_identidad) {
      newErrors.cedula_identidad = "La cédula es obligatoria";
    }
    if (!currentObrero.loteId) {
      newErrors.fecha = "El lote es obligatorio";
    }
    if (!currentObrero.funcion) {
      newErrors.funcion = "La función es obligatoria";
    }
    if (!currentObrero.estado) {
      newErrors.estado = "El estado es obligatorio";
    }
    if (!/^[a-zA-Z0-9]+-[a-zA-Z0-9]+$/.test(currentObrero.loteId)) {
      newErrors.loteId = "El formato de lote debe ser 'manzana-lote' (alfanumérico).";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length !== 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      return toast.error("Formulario no válido");
    }

    try {
      if (!currentObrero.id) {
        await axiosInstance.post(`${baseUrl}/api/obreros`, currentObrero);
        toast.success("Obrero agregado exitosamente");
      } else {
        await axiosInstance.put(`${baseUrl}/api/obreros/${currentObrero.id}`, currentObrero);
        toast.success("Obrero editado exitosamente");
      }
      fetchObreros();
      handleClose();
    } catch (error) {
      toast.error(error.response.data.error);
      handleClose();
    }
  };

  const handleEdit = (obrero) => {
    setCurrentObrero(obrero);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`${baseUrl}/api/obreros/${obreroToDelete.id}`);
      fetchObreros();
      setConfirmOpen(false);
      toast.success("Obrero eliminado exitosamente");
    } catch (error) {
      toast.error("Error al eliminar el obrero");
    }
  };

  const handleDeleteClick = (obrero) => {
    setObreroToDelete(obrero);
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleNewObreroChange = (e) => {
    const { name, value } = e.target;
    setCurrentObrero({ ...currentObrero, [name]: value });
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    const tableColumn = ["Nombre", "Apellido", "CI", "Fecha", "Función", "Estado"];
    const tableRows = [];

    // Recorrer los obreros para construir las filas de la tabla
    obreros.forEach((obrero) => {
      const obreroData = [
        obrero.nombre,
        obrero.apellido,
        obrero.cedula_identidad,
        new Date(obrero.createdAt).toLocaleDateString(),
        obrero.funcion,
        obrero.estado,
      ];
      tableRows.push(obreroData);
    });

    // Generar la tabla con jsPDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    // Nombre del archivo y descarga
    doc.save("listado_obreros.pdf");
  };

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Gestión de Obreros</Typography>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Agregar Obrero
        </Button>
      </Box>

      <Box display="flex" gap={2} marginBottom={2}>
        <TextField
          label="Nombre"
          value={filters.nombre || ""}
          onChange={(e) => setFilters({ ...filters, nombre: e.target.value })}
        />
        <TextField
          label="CI"
          value={filters.cedula_identidad || ""}
          onChange={(e) => setFilters({ ...filters, cedula_identidad: e.target.value })}
        />
        <TextField
          //   margin="dense"
          name="loteId"
          label="Manzana-Lote"
          type="text"
          //   fullWidth
          value={filters.loteId || ""} // Aseguramos que no sea undefined
          onChange={(e) => {
            const value = e.target.value;
            // Validar que el formato sea "manzana-lote" donde ambos son números
            // if (/^\d+-\d+$/.test(value) || value === "") {
            setFilters({ ...filters, loteId: value });
            // } else {
            //   toast.error("El formato debe ser 'manzana-lote', por ejemplo '1-1'.");
            // }
          }}
          error={!!errors.loteId}
          helperText={errors.loteId}
        />
        <TextField
          label="Fecha"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.fecha || ""}
          onChange={(e) => setFilters({ ...filters, fecha: e.target.value })}
        />
        <Button variant="contained" color="primary" onClick={fetchObreros}>
          Filtrar
        </Button>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Button variant="contained" color="secondary" onClick={downloadPdf}>
          Descargar PDF
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteSelected}
          disabled={selectedObreros.length === 0}
        >
          Eliminar Seleccionados
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }}></TableCell>
              <TableCell sx={{ fontSize: "20px" }}>Nombre</TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Apellido
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                CI
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Fecha de creación
              </TableCell>

              <TableCell sx={{ fontSize: "20px" }} align="right">
                Función
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                FML
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Estado
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {obreros.map((obrero) => (
              <TableRow key={obrero.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedObreros.includes(obrero.id)}
                    onChange={() => handleSelectObrero(obrero.id)}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {obrero.nombre}
                </TableCell>
                <TableCell align="right">{obrero.apellido}</TableCell>
                <TableCell align="right">{obrero.cedula_identidad}</TableCell>
                <TableCell align="right">{new Date(obrero.createdAt).toLocaleString()}</TableCell>
                <TableCell align="right">{obrero.funcion}</TableCell>
                {/* <TableCell align="right">{`143 - ${obrero.Lote}`}</TableCell> */}
                <TableCell align="right">{`143 - ${obrero.Lote.manzana} -${obrero.Lote.lote}`}</TableCell>
                <TableCell align="right">{obrero.estado}</TableCell>

                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleEdit(obrero)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="warning" onClick={() => handleDeleteClick(obrero)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Diálogo para agregar o editar obrero */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentObrero.id ? "Editar Obrero" : "Agregar Obrero"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="nombre"
            label="Nombre"
            type="text"
            fullWidth
            value={currentObrero.nombre}
            onChange={handleNewObreroChange}
            error={!!errors.nombre}
            helperText={errors.nombre}
          />
          <TextField
            margin="dense"
            name="apellido"
            label="Apellido"
            type="text"
            fullWidth
            value={currentObrero.apellido}
            onChange={handleNewObreroChange}
            error={!!errors.apellido}
            helperText={errors.apellido}
          />
          <TextField
            margin="dense"
            name="cedula_identidad"
            label="Cédula"
            type="text"
            fullWidth
            value={currentObrero.cedula_identidad}
            onChange={handleNewObreroChange}
            error={!!errors.cedula_identidad}
            helperText={errors.cedula_identidad}
          />
          {/* Campo para ingresar la manzana-lote */}
          <TextField
            margin="dense"
            name="loteId"
            label="Manzana-Lote"
            type="text"
            fullWidth
            value={currentObrero.manzana_lote || ""} // Aseguramos que no sea undefined
            onChange={(e) => {
              const value = e.target.value;
              // Validar que el formato sea "manzana-lote" donde ambos son números
              //   if (/^\d+-\d+$/.test(value) || value === "") {
              setCurrentObrero({ ...currentObrero, loteId: value, manzana_lote: value });
              //   } else {
              //     toast.error("El formato debe ser 'manzana-lote', por ejemplo '1-1'.");
              //   }
            }}
            error={!!errors.loteId}
            helperText={errors.loteId}
          />
          <TextField
            margin="dense"
            name="funcion"
            label="Función"
            type="text"
            fullWidth
            value={currentObrero.funcion}
            onChange={handleNewObreroChange}
            error={!!errors.funcion}
            helperText={errors.funcion}
          />
          <FormControl fullWidth margin="dense" error={!!errors.estado}>
            <InputLabel>Estado</InputLabel>
            <Select
              name="estado"
              value={currentObrero.estado}
              onChange={handleNewObreroChange}
              label="Estado"
            >
              <MenuItem value="activo">Activo</MenuItem>
              <MenuItem value="inactivo">Inactivo</MenuItem>
            </Select>
            {errors.estado && <p style={{ color: "red" }}>{errors.estado}</p>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSave}>Guardar</Button>
        </DialogActions>
      </Dialog>
      {/* Diálogo de confirmación para eliminar */}
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro de que deseas eliminar a este obrero?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose}>Cancelar</Button>
          <Button onClick={handleDelete}>Eliminar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Obreros;
