import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt"; // Icono de cámara
import Webcam from "react-webcam";
import Tesseract from "tesseract.js";

const EditMedicionModal = ({ open, handleClose, lotes, handleSave, medicionToEdit }) => {
  const [medicion, setMedicion] = useState("");
  const [LoteId, setLoteId] = useState("");
  const [paid, setPaid] = useState(false);
  const [id, setId] = useState(""); // Estado para el ID
  const [showWebcam, setShowWebcam] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const webcamRef = useRef(null);

  useEffect(() => {
    if (medicionToEdit) {
      setMedicion(medicionToEdit.medicion);
      setLoteId(medicionToEdit.LoteId);
      setPaid(medicionToEdit.paid);
      setId(medicionToEdit.id); // Inicializa el ID
    }
  }, [medicionToEdit]);

  const preprocessImage = (imageSrc) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = imageSrc;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // Ajustar el contraste (este es un ejemplo simple, puedes usar librerías como OpenCV para procesamiento avanzado)
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          data[i] = data[i] * 1.5; // Rojo
          data[i + 1] = data[i + 1] * 1.5; // Verde
          data[i + 2] = data[i + 2] * 1.5; // Azul
        }
        ctx.putImageData(imageData, 0, 0);
        resolve(canvas.toDataURL("image/jpeg"));
      };
    });
  };

  const handleCapture = async () => {
    setIsProcessing(true);
    const imageSrc = webcamRef.current.getScreenshot();

    if (!imageSrc) {
      console.error("Failed to capture image");
      setIsProcessing(false);
      return;
    }

    const preprocessedImage = await preprocessImage(imageSrc);

    Tesseract.recognize(preprocessedImage, "eng", {
      logger: (m) => console.log(m),
    })
      .then(({ data: { text } }) => {
        const extractedNumber = text.replace(/\D/g, "");
        setMedicion(extractedNumber);
        setShowWebcam(false);
        setIsProcessing(false);
      })
      .catch((err) => {
        console.error("Error processing image", err);
        setIsProcessing(false);
      });
  };

  const onSave = () => {
    handleSave({ id, medicion, LoteId, paid: paid ? 1 : 0 }); // Incluye el ID en el objeto a guardar
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Editar Medición</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="select-lote-label">Lote</InputLabel>
          <Select
            labelId="select-lote-label"
            value={LoteId}
            onChange={(e) => setLoteId(e.target.value)}
            label="Lote"
          >
            {lotes.map((lote) => (
              <MenuItem key={lote.id} value={lote.id}>
                {`Lote: ${lote.lote} Manzana: ${lote.manzana}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Medición"
            type="number"
            value={medicion}
            onChange={(e) => setMedicion(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton edge="end" onClick={() => setShowWebcam(true)}>
                  <CameraAltIcon />
                </IconButton>
              ),
            }}
          />
        </FormControl>

        {showWebcam && (
          <div style={{ position: "relative" }}>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width="100%"
              videoConstraints={{ facingMode: "environment" }}
            />
            {!isProcessing && (
              <Button
                onClick={handleCapture}
                style={{
                  position: "absolute",
                  bottom: 20,
                  right: 20,
                  backgroundColor: "#1976d2", // Azul Material-UI
                  color: "#fff", // Letra blanca
                }}
                variant="contained"
                color="primary"
              >
                Capturar
              </Button>
            )}
            {isProcessing && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <CircularProgress />
                <div>Procesando...</div>
              </div>
            )}
          </div>
        )}

        {/* <FormControlLabel
          control={
            <Switch
              checked={paid}
              onChange={(e) => setPaid(e.target.checked)}
              color="primary"
            />
          }
          label="Pago Realizado"
        /> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={onSave} color="primary">
          Actualizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMedicionModal;
