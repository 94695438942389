import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TablePagination,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomSwitch from "../../components/CustomSwitch";
import UserContext from "../../UserContext";
import { useNavigate } from "react-router-dom";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const baseUrl = process.env.REACT_APP_BASE_URL;

const LotesMant = () => {
  const { user } = useContext(UserContext);

  const [lotes, setLotes] = useState([]);
  const [detailOpen, setDetailOpen] = useState(false);
  const [loteDetails, setLoteDetails] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchLotes();
  }, []);

  const fetchLotes = async () => {
    const response = await axiosInstance.get(`${baseUrl}/api/lotes`);
    setLotes(response.data);
  };

  const handleDetailOpen = async (loteId) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/api/lotes/${loteId}`);
      setLoteDetails(response.data);
      setDetailOpen(true);
    } catch (error) {
      console.error("Error al obtener detalles del lote:", error);
    }
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
    setLoteDetails(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigate = useNavigate();

  const handleRedirectToMediciones = (loteId) => {
    navigate(`/mediciones/${loteId}`);
  };

  // Logic for displaying the correct rows per page
  const paginatedLotes = lotes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Gestión de Lotes</Typography>
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }}>Lote</TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Manzana
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Estado
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Propietario
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedLotes.map((lote) => (
              <TableRow key={lote.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {lote.lote}
                </TableCell>
                <TableCell align="right">{lote.manzana}</TableCell>
                <TableCell align="right">{lote.estado}</TableCell>
                <TableCell align="right">
                  {lote.Users.length > 0 ? lote.Users[0].username : "-"}
                </TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleDetailOpen(lote.id)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleRedirectToMediciones(lote.id)}>
                    <ContentPasteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={lotes.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage="Mostrar"
        />
      </TableContainer>
      <Dialog open={detailOpen} onClose={handleDetailClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Detalles del Lote</DialogTitle>
        <DialogContent>
          {loteDetails && (
            <>
              <Typography sx={{ padding: "10px" }} variant="body1">
                Lote: {loteDetails.lote.lote}
              </Typography>
              <Typography sx={{ padding: "10px" }} variant="body1">
                Manzana: {loteDetails.lote.manzana}
              </Typography>
              <Typography sx={{ padding: "10px" }} variant="body1">
                Estado: {loteDetails.lote.estado}
              </Typography>
              <Typography sx={{ padding: "10px" }} variant="body1">
                Propietario: {loteDetails.owners.map((owner) => owner.username).join(", ")}
              </Typography>
              <Typography sx={{ padding: "10px" }} variant="body1">
                Copropietario: {loteDetails.guests.map((guest) => guest.username).join(", ")}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDetailClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LotesMant;
