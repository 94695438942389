import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Box,
  IconButton,
  DialogActions,
  Modal,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import QRCode from "qrcode.react";
import QrScanner from "react-qr-scanner";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Equipos = () => {
  const [equipos, setEquipos] = useState([]);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedEquipo, setSelectedEquipo] = useState(null);
  const [qrOpen, setQrOpen] = useState(false);
  const [qrData, setQrData] = useState("");
  const [filters, setFilters] = useState({
    serie: "",
    nombre: "",
    fecha_ultimo_mantenimiento: "",
    fecha_proximo_mantenimiento: "",
    fecha: "", // Agrega esta línea
  });
  const [openQrModal, setOpenQrModal] = useState(false);
  const [qrResult, setQrResult] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [equipoForm, setEquipoForm] = useState({
    serie: "",
    nombre: "",
    modelo: "",
    descripcion: "",
    fecha_ultimo_mantenimiento: "",
    fecha_proximo_mantenimiento: "",
  });
  const navigate = useNavigate();
  useEffect(() => {
    fetchEquipos();
  }, []);

  const fetchEquipos = async (sort = "") => {
    try {
      const params = new URLSearchParams({ ...filters, sort });
      const response = await axiosInstance.get(`${baseUrl}/api/equipos?${params}`);
      setEquipos(response.data);
    } catch (error) {
      console.error("Error fetching equipos:", error);
    }
  };
  //   console.log("ASDASDASDAS", qrResult);

  const handleOpenQrModal = () => setOpenQrModal(true);
  const handleCloseQrModal = () => setOpenQrModal(false);

  const handleRedirectToHistorial = (id) => {
    navigate(`/equipos/historial/${id}`);
  };

  const handleScan = (data) => {
    if (data) {
      //   console.log("DATA", data.text.serie);
      let result = JSON.parse(data.text);
      console.log("ASDADS", result);

      setQrResult(data.text);
      //   handleCloseQrModal();
      setFilters({ ...filters, serie: result.serie });
      // Aquí puedes manejar el resultado del QR, por ejemplo, guardarlo o enviarlo al backend
    }
  };

  const filtrar = () => {
    handleCloseQrModal();
    fetchEquipos();
  };

  const handleError = (err) => {
    console.error("Error al escanear el QR:", err);
  };

  const previewStyle = {
    height: 240,
    width: 320,
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleGenerateQR = (equipo) => {
    setQrData(JSON.stringify({ serie: equipo.serie }));
    setQrOpen(true);
  };

  const handleViewDetails = (equipo) => {
    setSelectedEquipo(equipo);
    setViewOpen(true);
  };

  const handleCloseView = () => {
    setViewOpen(false);
    setSelectedEquipo(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEquipo(null);
    setIsEdit(false);
  };

  const handleAddEditEquipo = () => {
    if (isEdit) {
      updateEquipo();
    } else {
      addEquipo();
    }
  };

  const addEquipo = async () => {
    try {
      await axiosInstance.post(`${baseUrl}/api/equipos`, equipoForm);
      toast.success("Equipo agregado exitosamente");
      fetchEquipos();
      handleClose();
    } catch (error) {
      toast.error("Error al agregar equipo");
    }
  };

  const updateEquipo = async () => {
    try {
      await axiosInstance.put(`${baseUrl}/api/equipos/${selectedEquipo.id}`, equipoForm);
      toast.success("Equipo actualizado exitosamente");
      fetchEquipos();
      handleClose();
    } catch (error) {
      toast.error("Error al actualizar equipo");
    }
  };

  const handleEditClick = (equipo) => {
    setIsEdit(true);
    setSelectedEquipo(equipo);
    setEquipoForm({
      serie: equipo.serie,
      nombre: equipo.nombre,
      modelo: equipo.modelo,
      descripcion: equipo.descripcion,
      fecha_ultimo_mantenimiento: equipo.fecha_ultimo_mantenimiento,
      fecha_proximo_mantenimiento: equipo.fecha_proximo_mantenimiento,
    });
    setOpen(true);
  };

  const handleDeleteClick = (equipo) => {
    setSelectedEquipo(equipo);
    setDeleteOpen(true);
  };

  const handleDeleteEquipo = async () => {
    try {
      await axiosInstance.delete(`${baseUrl}/api/equipos/${selectedEquipo.id}`);
      toast.success("Equipo eliminado exitosamente");
      fetchEquipos();
      setDeleteOpen(false);
      setSelectedEquipo(null);
    } catch (error) {
      toast.error("Error al eliminar equipo");
    }
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    const tableColumn = [
      "Serie",
      "Nombre",
      "Modelo",
      "Descripción",
      "Último Mantenimiento",
      "Próximo Mantenimiento",
    ];
    const tableRows = equipos.map((equipo) => [
      equipo.serie,
      equipo.nombre,
      equipo.modelo,
      equipo.descripcion,
      new Date(equipo.fecha_ultimo_mantenimiento).toLocaleDateString(),
      new Date(equipo.fecha_proximo_mantenimiento).toLocaleDateString(),
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });
    doc.save("listado_equipos.pdf");
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setEquipoForm({ ...equipoForm, [name]: value });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Gestión de Equipos</Typography>
        <Box>
          <Button variant="contained" color="primary" onClick={downloadPdf}>
            Descargar PDF
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenQrModal}
            style={{ marginLeft: "8px" }}
          >
            Escanear QR
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setIsEdit(false);
              setEquipoForm({
                serie: "",
                nombre: "",
                modelo: "",
                descripcion: "",
                fecha_ultimo_mantenimiento: "",
                fecha_proximo_mantenimiento: "",
              });
              setOpen(true);
            }}
            sx={{ marginLeft: 2 }}
          >
            Agregar Equipo
          </Button>
        </Box>
      </Box>

      <Box display="flex" gap={2} marginBottom={2}>
        <TextField
          label="Nombre"
          name="nombre"
          value={filters.nombre}
          onChange={handleFilterChange}
        />
        <TextField
          label="Modelo"
          name="modelo"
          value={filters.modelo}
          onChange={handleFilterChange}
        />
        <TextField
          label="Nro Serie"
          name="serie"
          value={filters.serie}
          onChange={handleFilterChange}
        />

        {/* <TextField
          label="Último Mantenimiento"
          name="fecha_ultimo_mantenimiento"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.fecha_ultimo_mantenimiento}
          onChange={handleFilterChange}
        />
        <TextField
          label="Próximo Mantenimiento"
          name="fecha_proximo_mantenimiento"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.fecha_proximo_mantenimiento}
          onChange={handleFilterChange}
        />
        <TextField
          label="Fecha de Mantenimiento"
          name="fecha"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.fecha}
          onChange={handleFilterChange}
        /> */}
        <Button variant="contained" color="primary" onClick={fetchEquipos}>
          Filtrar
        </Button>
      </Box>

      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="Equipos table">
          <TableHead>
            <TableRow>
              <TableCell>Nro Serie</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Modelo</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Último Mantenimiento</TableCell>
              <TableCell>Próximo Mantenimiento</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {equipos.map((equipo) => (
              <TableRow
                key={equipo.id}
                style={{ backgroundColor: equipo.alerta ? "red" : "transparent" }}
              >
                <TableCell>{equipo.serie}</TableCell>
                <TableCell>{equipo.nombre}</TableCell>
                <TableCell>{equipo.modelo}</TableCell>
                <TableCell>{equipo.descripcion}</TableCell>
                <TableCell>
                  {new Date(equipo.fecha_ultimo_mantenimiento).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  {new Date(equipo.fecha_proximo_mantenimiento).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleViewDetails(equipo)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleEditClick(equipo)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDeleteClick(equipo)}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleGenerateQR(equipo)}>
                    <QrCodeIcon />
                  </IconButton>
                  <IconButton color="primary" onClick={() => handleRedirectToHistorial(equipo.id)}>
                    <ContentPasteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog to add/edit equipo */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEdit ? "Editar Equipo" : "Agregar Equipo"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Nombre"
            name="nombre"
            value={equipoForm.nombre}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nro Serie"
            name="serie"
            value={equipoForm.serie}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Modelo"
            name="modelo"
            value={equipoForm.modelo}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Descripción"
            name="descripcion"
            value={equipoForm.descripcion}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          {isEdit ? null : (
            <TextField
              label="Fecha Último Mantenimiento"
              name="fecha_ultimo_mantenimiento"
              type="date"
              value={equipoForm.fecha_ultimo_mantenimiento}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          )}
          {isEdit ? null : (
            <TextField
              label="Fecha Próximo Mantenimiento"
              name="fecha_proximo_mantenimiento"
              type="date"
              value={equipoForm.fecha_proximo_mantenimiento}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleAddEditEquipo} color="primary">
            {isEdit ? "Guardar Cambios" : "Agregar"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog to view details */}
      <Dialog open={viewOpen} onClose={handleCloseView} fullWidth maxWidth="sm">
        <DialogTitle sx={{ textAlign: "center", bgcolor: "#3f51b5", color: "#fff" }}>
          Detalles del Equipo
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" color="primary">
              Nro de Serie: <strong>{selectedEquipo?.serie}</strong>
            </Typography>
            <Typography variant="h6" color="primary">
              Nombre: <strong>{selectedEquipo?.nombre}</strong>
            </Typography>
            <Typography variant="h6" color="primary">
              Modelo: <strong>{selectedEquipo?.modelo}</strong>
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Descripción: {selectedEquipo?.descripcion}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" color="textSecondary">
              Fecha Último Mantenimiento:{" "}
              <strong>
                {new Date(selectedEquipo?.fecha_ultimo_mantenimiento).toLocaleDateString()}
              </strong>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Fecha Próximo Mantenimiento:{" "}
              <strong>
                {new Date(selectedEquipo?.fecha_proximo_mantenimiento).toLocaleDateString()}
              </strong>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseView} variant="contained" color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation dialog to delete equipo */}
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>Eliminar Equipo</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro de que deseas eliminar este equipo?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteEquipo} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openQrModal}
        onClose={handleCloseQrModal}
        aria-labelledby="qr-scan-modal-title"
        aria-describedby="qr-scan-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 320,
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="qr-scan-modal-title">Escanear QR</h2>
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "100%" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={filtrar}
            style={{ marginLeft: "8px" }}
          >
            Escanear QR
          </Button>
        </Box>
      </Modal>
      <Dialog open={qrOpen} onClose={() => setQrOpen(false)}>
        <DialogTitle>QR del Equipo</DialogTitle>
        <DialogContent>
          <QRCode value={qrData} size={256} level="H" includeMargin={true} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              const canvas = document.querySelector("canvas");
              const qrImage = canvas.toDataURL("image/png");
              const pdf = new jsPDF();
              pdf.addImage(qrImage, "PNG", 15, 40, 180, 160);
              pdf.save("QR_Equipo.pdf");
            }}
            color="primary"
          >
            Imprimir QR
          </Button>
          <Button onClick={() => setQrOpen(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Equipos;
