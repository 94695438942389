import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  Box,
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import esLocale from "date-fns/locale/es";
import axiosInstance from "../../axiosInstance";

const baseUrl = process.env.REACT_APP_BASE_URL;
const RegistroObreroList = () => {
  const [registros, setRegistros] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    // Obtener todos los registros al cargar la pantalla
    fetchRegistros();
  }, []);

  const fetchRegistros = async (startDate = null, endDate = null) => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/api/obreros/getRegistro`, {
        params: {
          startDate: startDate ? startDate.toISOString() : "",
          endDate: endDate ? endDate.toISOString() : "",
        },
      });
      setRegistros(response.data);
    } catch (error) {
      console.error("Error al obtener los registros:", error);
    }
  };

  const handleFilter = () => {
    fetchRegistros(startDate, endDate);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, p: 3, boxShadow: 3, borderRadius: 2, backgroundColor: "white" }}>
        <Typography variant="h5" align="center" gutterBottom>
          Registro de Entradas y Salidas
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
              <DateTimePicker
                label="Fecha de inicio"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={esLocale}>
              <DateTimePicker
                label="Fecha de término"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button variant="contained" color="primary" fullWidth onClick={handleFilter}>
              Filtrar
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>Apellido</TableCell>
                <TableCell>Cédula</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Hora</TableCell>
                <TableCell>Acción</TableCell>
                <TableCell>Lote</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {registros.map((registro) => (
                <TableRow key={registro.id}>
                  <TableCell>{registro.Obrero.nombre}</TableCell>
                  <TableCell>{registro.Obrero.apellido}</TableCell>
                  <TableCell>{registro.Obrero.cedula_identidad}</TableCell>
                  <TableCell>{new Date(registro.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(registro.createdAt).toLocaleTimeString()}</TableCell>
                  <TableCell>{registro.action}</TableCell>
                  <TableCell>
                    {registro.Obrero.Lote.lote} - Manzana {registro.Obrero.Lote.manzana}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default RegistroObreroList;
