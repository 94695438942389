import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Ajusta la ruta al logo según tu estructura de archivos
import { toast, ToastContainer } from "react-toastify";
const InvitadoForm = () => {
  const { token, lote } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [invitacion, setInvitacion] = useState(null);
  const [invitados, setInvitados] = useState([]);
  const [cedulaBusqueda, setCedulaBusqueda] = useState(""); // Estado para el input de búsqueda por cédula
  const [fotos, setFotos] = useState([]); // Estado para almacenar las fotos de los invitados
  useEffect(() => {
    const fetchInvitacion = async () => {
      try {
        const response = await axiosInstance.get(`/api/invitaciones/${token}`);
        setInvitacion(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching invitacion:", error);
        setLoading(false);
      }
    };
    fetchInvitacion();
  }, [token]);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newInvitados = [...invitados];

    // Validaciones específicas para cada campo
    if (name === "cedula_identidad") {
      // Permitir solo números
      if (/^\d*$/.test(value)) {
        newInvitados[index][name] = value;
      }
    } else if (name === "edad") {
      // Permitir solo números y mayores a 0
      if (/^\d*$/.test(value) && (value === "" || parseInt(value, 10) > 0)) {
        newInvitados[index][name] = value;
      }
    } else if (name === "email") {
      // Validación básica de email con regex
      newInvitados[index][name] = value;
    } else {
      newInvitados[index][name] = value;
    }

    setInvitados(newInvitados);
  };

  const handleAddInvitado = () => {
    if (invitados.length < invitacion.cantidadMaxInvitados) {
      setInvitados([
        ...invitados,
        { nombre: "", cedula_identidad: "", genero: "", edad: "", email: "" },
      ]);
    }
  };

  const handleFileChange = (index, e) => {
    const file = e.target.files[0];
    const newFotos = [...fotos];

    // Guardar el archivo y la URL para vista previa
    if (file) {
      newFotos[index] = {
        file,
        preview: URL.createObjectURL(file), // Generar una URL temporal para mostrar la imagen
      };
      setFotos(newFotos);
    }
  };

  const handleRemoveInvitado = (index) => {
    const newInvitados = invitados.filter((_, i) => i !== index);
    setInvitados(newInvitados);
  };

  const handleCedulaChange = (e) => {
    setCedulaBusqueda(e.target.value);
  };

  const handleBuscarInvitado = async (index) => {
    try {
      const response = await axiosInstance.get(`/api/invitados/invitadosPortero/getAll`);
      const invitadoExistente = response.data.find(
        (inv) => inv.cedula_identidad === cedulaBusqueda
      );

      if (invitadoExistente) {
        // Si se encuentra el invitado, autocompletar los datos
        const newInvitados = [...invitados];
        newInvitados[index] = {
          nombre: invitadoExistente.nombre,
          cedula_identidad: invitadoExistente.cedula_identidad,
          genero: invitadoExistente.genero,
          edad: invitadoExistente.edad,
          email: invitadoExistente.email,
        };
        setInvitados(newInvitados);
        setCedulaBusqueda(""); // Limpiar el input de búsqueda
      } else {
        alert("Invitado no encontrado. Por favor complete el formulario manualmente.");
      }
    } catch (error) {
      console.error("Error fetching invitado:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    // Validar datos de los invitados antes de enviar
    for (let i = 0; i < invitados.length; i++) {
      const invitado = invitados[i];

      if (!/^\d+$/.test(invitado.cedula_identidad)) {
        toast.error("La cédula debe contener solo números.");
        return;
      }
      if (!/^\d+$/.test(invitado.edad) || parseInt(invitado.edad, 10) <= 0) {
        toast.error("La edad debe ser un número positivo.");
        return;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(invitado.email)) {
        toast.error("El email no es válido.");
        return;
      }

      // Agregar datos de los invitados al FormData
      formData.append(`invitados[${i}][nombre]`, invitado.nombre);
      formData.append(`invitados[${i}][cedula_identidad]`, invitado.cedula_identidad);
      formData.append(`invitados[${i}][genero]`, invitado.genero);
      formData.append(`invitados[${i}][edad]`, invitado.edad);
      formData.append(`invitados[${i}][email]`, invitado.email);

      // Si hay una foto, añadirla al FormData (con el campo 'fotos')
      if (fotos[i]) {
        formData.append("fotos", fotos[i].file); // Cambiado a 'fotos'
      }
    }

    try {
      const response = await axiosInstance.post(`/api/invitaciones/${token}/invitados`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate(`/form-success`, {
        state: {
          invitacion: response.data,
          lote: lote,
          invitacionToken: token,
          fechaInicio: invitacion.fechaInicio,
          fechaTermino: invitacion.fechaTermino,
        },
      });
    } catch (error) {
      console.error("Error saving invitados:", error);
      toast.error(error.response.data.error);
    }
  };
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <ToastContainer />
      <Box sx={{ my: 4, textAlign: "center" }}>
        {/* Logo */}
        <Box mb={4}>
          <img src="/assets/logo-color.png" alt="Logo" style={{ height: 200 }} />
        </Box>

        <Typography variant="h4" component="h1" gutterBottom>
          Carga tus invitados
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {invitados.map((invitado, index) => (
              <Grid item xs={12} key={index}>
                <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, position: "relative" }}>
                  {/* Botón de cerrar */}
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveInvitado(index)}
                    sx={{ position: "absolute", top: 8, right: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>

                  <Typography variant="h6" gutterBottom>
                    Invitado {index + 1}
                  </Typography>

                  {/* Input para buscar por cédula */}
                  <Box display="flex" alignItems="center" mb={2}>
                    <TextField
                      label="Buscar por Cédula"
                      value={cedulaBusqueda}
                      onChange={handleCedulaChange}
                      fullWidth
                      margin="normal"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleBuscarInvitado(index)}
                      sx={{ ml: 2 }}
                    >
                      Buscar
                    </Button>
                  </Box>

                  <TextField
                    label="Nombre"
                    name="nombre"
                    value={invitado.nombre}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Cédula de Identidad"
                    name="cedula_identidad"
                    value={invitado.cedula_identidad}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                    margin="normal"
                  />
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Género</InputLabel>
                    <Select
                      name="genero"
                      value={invitado.genero}
                      onChange={(e) => handleChange(index, e)}
                    >
                      <MenuItem value="masculino">Masculino</MenuItem>
                      <MenuItem value="femenino">Femenino</MenuItem>
                      <MenuItem value="otro">Otro</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Edad"
                    name="edad"
                    type="number"
                    value={invitado.edad}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={invitado.email}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Foto"
                    name="foto"
                    type="file"
                    inputProps={{ accept: "image/*" }} // Solo permitir imágenes
                    onChange={(e) => handleFileChange(index, e)}
                    fullWidth
                    margin="normal"
                  />
                  {/* Mostrar vista previa si hay una imagen seleccionada */}
                  {fotos[index] && fotos[index].preview && (
                    <Box mt={2}>
                      <img
                        src={fotos[index].preview}
                        alt={`Preview Invitado ${index + 1}`}
                        style={{
                          width: "200px",
                          maxHeight: "200px",
                          //   objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                    </Box>
                  )}
                  <Divider sx={{ my: 2 }} />
                </Paper>
              </Grid>
            ))}
          </Grid>

          <Box mt={3}>
            {invitados.length < invitacion.cantidadMaxInvitados && (
              <Button
                type="button"
                onClick={handleAddInvitado}
                variant="contained"
                color="secondary"
                sx={{ mr: 2 }}
              >
                Añadir invitado
              </Button>
            )}
            {invitados.length > 0 && (
              <Button type="submit" variant="contained" color="primary">
                Enviar
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default InvitadoForm;
