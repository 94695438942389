import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import { useSnackbar } from "notistack";

import {
  Box,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CopyIcon from "@mui/icons-material/Send";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import CopyIcon from "@mui/icons-material/Copy";
import ShareIcon from "@mui/icons-material/Share";
import { toast, ToastContainer } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

const Invitados = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [invitados, setInvitados] = useState([]);
  const [linkOpen, setLinkOpen] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [open, setOpen] = useState(false);
  const [lotes, setLotes] = useState([]);
  const [errors, setErrors] = useState({
    cedula_identidad: "",
    edad: "",
    email: "",
  });
  const [emailOpen, setEmailOpen] = useState(false);
  const [currentInvitado, setCurrentInvitado] = useState({
    nombre: "",
    cedula_identidad: "",
    genero: "",
    edad: "",
    email: "",
  });
  const [currentEmail, setCurrentEmail] = useState({
    lote: "",
    subject: "",
    message: "",
    fechaInicio: null,
    fechaTermino: null,
  });
  const [selectedInvitado, setSelectedInvitado] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchInvitados();
    fetchLotes();
  }, []);

  const fetchInvitados = async () => {
    const response = await axiosInstance.get(`${baseUrl}/api/invitados`);
    console.log(response.data);

    setInvitados(response.data);
  };

  const handleOpen = () => {
    setCurrentInvitado({
      nombre: "",
      cedula_identidad: "",
      genero: "",
      edad: "",
      email: "",
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchLotes = async () => {
    const response = await axiosInstance.get(`${baseUrl}/api/lotes/getMyLotes`);
    setLotes(response.data);
  };

  const handleEmailOpen = (invitado) => {
    setSelectedInvitado(invitado);
    setEmailOpen(true);
  };

  const handleEmailClose = () => {
    setEmailOpen(false);
  };

  const validateFields = () => {
    let valid = true;
    let newErrors = { cedula_identidad: "", edad: "", email: "" };

    if (!/^\d+$/.test(currentInvitado.cedula_identidad)) {
      newErrors.cedula_identidad = "La cédula debe contener solo números.";
      valid = false;
    }

    if (!/^\d+$/.test(currentInvitado.edad)) {
      newErrors.edad = "La edad debe contener solo números.";
      valid = false;
    }

    if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(currentInvitado.email)) {
      newErrors.email = "El correo electrónico no es válido.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSave = async () => {
    if (validateFields()) {
      if (currentInvitado.id) {
        await axiosInstance.put(`${baseUrl}/api/invitados/${currentInvitado.id}`, currentInvitado);
      } else {
        await axiosInstance.post(`${baseUrl}/api/invitados`, currentInvitado);
      }
      fetchInvitados();
      handleClose();
    }
  };

  const handleCreateInvitation = async () => {
    if (selectedInvitado) {
      setLoading(true);
      try {
        const response = await axiosInstance.post(`${baseUrl}/api/invitaciones`, {
          invitadoId: selectedInvitado.id,
          loteId: currentEmail.lote,
          fechaInicio: currentEmail.fechaInicio,
          fechaTermino: currentEmail.fechaTermino,
          cantidad: currentEmail.cantidad,
        });

        setGeneratedLink(response.data.link);
        setLinkOpen(true);
        fetchInvitados();
        toast.success("Invitación creada exitosamente");
      } catch (error) {
        console.error("Error al crear la invitación:", error);
        toast.error(error.response?.data?.error || "Error al crear la invitación");
      } finally {
        setLoading(false);
        handleEmailClose();
      }
    }
  };

  const handleEdit = (invitado) => {
    setCurrentInvitado(invitado);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    await axiosInstance.delete(`${baseUrl}/api/invitados/${id}`);
    fetchInvitados();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentInvitado({ ...currentInvitado, [name]: value });
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setCurrentEmail({ ...currentEmail, [name]: value });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Gestión de Invitados</Typography>
        <Box>
          {/* <Button variant="contained" color="primary" onClick={handleOpen} sx={{ marginRight: 2 }}>
            Agregar Invitado
          </Button> */}
          <Button variant="contained" color="secondary" onClick={handleEmailOpen}>
            Enviar Invitación
          </Button>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap" gap={3}>
        {invitados.map((invitado) => (
          <Card key={invitado.id} sx={{ minWidth: 275, boxShadow: 3 }}>
            <CardHeader
              title={invitado.nombre}
              subheader={invitado.email}
              action={
                <IconButton onClick={() => handleEdit(invitado)}>
                  <EditIcon color="primary" />
                </IconButton>
              }
              sx={{ backgroundColor: "#f5f5f5" }}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                <strong>Cédula de Identidad:</strong> {invitado.cedula_identidad}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Género:</strong> {invitado.genero}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <strong>Edad:</strong> {invitado.edad}
              </Typography>
              {/* Fechas con texto claro y formato mejorado */}
              <Typography variant="body2" color="text.secondary" mt={1}>
                <strong>Válido desde:</strong>{" "}
                {invitado.ultimaInvitacion?.fechaInicio
                  ? new Date(invitado.ultimaInvitacion.fechaInicio).toLocaleString()
                  : "No asignado"}{" "}
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={1}>
                <strong>Válido hasta:</strong>{" "}
                {invitado.ultimaInvitacion?.fechaTermino
                  ? new Date(invitado.ultimaInvitacion.fechaTermino).toLocaleString()
                  : "No asignado"}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "space-between" }}>
              <IconButton onClick={() => handleDelete(invitado.id)} color="error">
                <DeleteIcon />
              </IconButton>
              {/* Puedes descomentar el botón de enviar aquí si es necesario */}
              {/* <IconButton onClick={() => handleEmailOpen(invitado)} color="primary">
          <SendIcon />
        </IconButton> */}
            </CardActions>
          </Card>
        ))}
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentInvitado.id ? "Editar Invitado" : "Agregar Invitado"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="nombre"
            label="Nombre"
            type="text"
            fullWidth
            value={currentInvitado.nombre}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="cedula_identidad"
            label="Cédula de Identidad"
            type="text"
            fullWidth
            value={currentInvitado.cedula_identidad}
            onChange={handleChange}
            error={!!errors.cedula_identidad}
            helperText={errors.cedula_identidad}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Género</InputLabel>
            <Select name="genero" value={currentInvitado.genero} onChange={handleChange}>
              <MenuItem value="masculino">Masculino</MenuItem>
              <MenuItem value="femenino">Femenino</MenuItem>
              <MenuItem value="otro">otro</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="edad"
            label="Edad"
            type="number"
            fullWidth
            value={currentInvitado.edad}
            onChange={handleChange}
            error={!!errors.edad}
            helperText={errors.edad}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={currentInvitado.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={emailOpen} onClose={handleEmailClose}>
        <DialogTitle>Enviar Invitación</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              sx={{ marginTop: "10px", marginBottom: "10px" }}
              label="Fecha de Inicio"
              value={currentEmail.fechaInicio}
              onChange={(newValue) => setCurrentEmail({ ...currentEmail, fechaInicio: newValue })}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
            />
            <DateTimePicker
              sx={{ marginTop: "10px", marginBottom: "10px" }}
              label="Fecha de Término"
              value={currentEmail.fechaTermino}
              onChange={(newValue) => setCurrentEmail({ ...currentEmail, fechaTermino: newValue })}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
            />
          </LocalizationProvider>

          <TextField
            margin="dense"
            name="cantidad"
            label="Cantidad de Invitados"
            type="number"
            fullWidth
            value={currentEmail.cantidad}
            onChange={(e) => setCurrentEmail({ ...currentEmail, cantidad: e.target.value })}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="lote-select-label">Seleccionar Lote</InputLabel>
            <Select
              labelId="lote-select-label"
              value={currentEmail.lote}
              onChange={(e) => setCurrentEmail({ ...currentEmail, lote: e.target.value })}
              input={<Input />}
            >
              {lotes.map((lote) => (
                <MenuItem key={lote.id} value={lote.id}>
                  Lote : {lote.lote} - Manzana : {lote.manzana}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEmailClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleCreateInvitation} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Enviar"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={linkOpen} onClose={() => setLinkOpen(false)}>
        <DialogTitle>Enlace de Invitación</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Aquí está el enlace generado para la invitación:</Typography>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            value={generatedLink}
            InputProps={{ readOnly: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(generatedLink);
              enqueueSnackbar("Enlace copiado al portapapeles", { variant: "success" });
            }}
            color="primary"
            startIcon={<CopyIcon />}
          >
            Copiar Enlace
          </Button>
          <Button
            onClick={() => {
              window.open(generatedLink, "_blank");
            }}
            color="primary"
            startIcon={<ShareIcon />}
          >
            Compartir Enlace
          </Button>
          <Button onClick={() => setLinkOpen(false)} color="secondary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Invitados;
